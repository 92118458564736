<template src="./fxoverridewrite.html" />
<style scoped src="./fxoverridewrite.css" />
<script>
import accountConstants from '@/constants/AccountConstants.vue'
import fxService from '@/services/FXServices.vue'
import Loading from 'vue-loading-overlay'
import modal from '@/components/modal/notification/modal'
import { required } from 'vuelidate/lib/validators'

export default {
    name: "fxoverridewrite",
    components: {
        Loading, modal
    },
    data() {
        return {
            remittanceGateways: accountConstants.remittanceGatewayId,
            targetCurrency: [],
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            clientApp: null,
            clientAppVersion: null,
            remittanceGateway: null,
            currency: null,
            fxRate: null
        }
    },
    methods: {
        addFxTransaction() {
            this.$v.$touch()
            if(this.$v.$invalid) {
                return
            }
            this.loaderVisible = true
            try {
                fxService.methods.AddFXOverrideRate({
                    "ClientApp": this.clientApp,
                    "ClientAppVer": this.clientAppVersion,
                    "FXRate": (+this.fxRate).toFixed(6),
                    "RemittanceGatewayID": this.remittanceGateway,
                    "TargetCurrency": this.currency
                })
                    .then(res => {
                        this.loaderVisible = false
                        this.clearAll()
                        this.showAlert(1, 'Added Successfully')
                    })
                    .catch(err => {
                        console.log(err)
                        this.loaderVisible = false
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })

            } catch (error) {
                this.loaderVisible = false
            }
        },
        changeCurrency() {
            this.currency = this.remittanceGateways.find(rg=> rg.Value == this.remittanceGateway)?.Currency ?? null
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        clearAll () {
            this.clientApp = null
            this.clientAppVersion = null
            this.fxRate = null
            this.remittanceGateway = null
            this.currency = null
            this.$v.$reset()
        }
    },
    validations : {
        clientApp : { required },
        clientAppVersion : { required },
        fxRate : { required },
        currency: { required },
        remittanceGateway: { required }
    },
    mounted() {
        try {
            let uniqueCurrency = new Set()
            accountConstants.countryList.forEach(ite => uniqueCurrency.add(ite.Currency))
            this.targetCurrency = Array.from(uniqueCurrency).map(item => {
                return {
                    text: item,
                    value: item
                }
            })
        } catch (error) {
            //
        }
    }
}
</script>